<template>
  <div justify="center">
    <v-icon
      class="mr-2"
      small
      @click.stop="openForm"
    >
      {{ icons.mdiEye }}
    </v-icon>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="900px"
    >
      <div>
        <v-card class="px-6 py-6">
          <div
            class="d-flex justify-center"
            style="font-size: 18px; letter-spacing: 1px;"
          >
            <span><strong>購買支払詳細</strong></span>
          </div>
          <v-row
            class="ma-4"
            no-gutters
          >
            <v-col
              cols="5"
              sm="2"
              style="font-size: 14px; line-height: 24px; font-weight:bold;"
            >
              <div>
                <span>支払年月</span>
              </div>
              <div>
                <span>仕入先名</span>
              </div>
              <div>
                <span>仕入先コード</span>
              </div>
            </v-col>
            <v-col
              cols="7"
              sm="4"
              style="font-size: 14px; line-height: 24px;"
            >
              <div>
                <span>2022年9月31日</span>
              </div>
              <div>
                <span>江戸川商事</span>
              </div>
              <div>
                <span>JP1300</span>
              </div>
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-card-actions
                class="d-flex justify-end"
                style="height: 60px"
              >
                <v-btn
                  color="primary"
                  class="me-3"
                  @click="goBack"
                >
                  <span>発送</span>
                </v-btn>
                <v-btn
                  color="secondary"
                  outlined
                  class="me-3"
                  @click="goBack"
                >
                  <span>ダウンロード</span>
                </v-btn>
              </v-card-actions>
            </v-col>
          </v-row>
          <v-data-table
            ref="datatable"
            :headers="headers"
            :items="items"
            sort-by
          >
            <template v-slot:[`item.product_name`]="{ item }">
              <v-tooltip
                v-if="item.product_name"
                bottom
              >
                <template v-slot:activator="{ on, attrs }">
                  <span
                    v-bind="attrs"
                    v-on="on"
                  > {{ item.product_name }}</span>
                </template>
                <span>JANコード：{{ item.jan }}<br />メーカー品番：{{ item.sku }}</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.price`]="{ item }">
              <label>{{ item.price.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') }}</label>
            </template>
            <template v-slot:[`item.total`]="{ item }">
              <label>{{ item.total.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') }}</label>
            </template>
          </v-data-table>
          <v-row class="mt-3 mb-6">
            <v-spacer></v-spacer>
            <v-col
              cols="6"
              sm="2"
            >
              <div>
                <span>合計：{{ TotalPrice }}</span>
              </div>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              class="me-3"
              @click="goBack"
            >
              <span style="color:#fff">OK</span>
            </v-btn>
            <v-btn
              color="secondary"
              outlined
              class="me-3"
              @click="dialog = false"
            >
              <span>キャンセル</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </v-dialog>
  </div>
</template>

<script>
/* eslint-disable */
import {
  mdiEye,
} from '@mdi/js'
/* eslint-disable */

export default {
  data: () => ({
    dialog: false,
    icons: {
      mdiEye,
    },
    date: [],
    headers: [
    {
        text: 'ID',
        value: 'id',
        align: 'left',
        width: '8%',
        fixed: true,
      },
      {
        text: '仕入日',
        value: 'date',
        width: '16%',
        align: 'left',
        sortable: true,
        fixed: false,
      },
      {
        text: 'メーカー品名',
        value: 'product_name',
        align: 'left',
        width: '30%',
        fixed: true,
      },
      {
        text: 'カートン',
        value: 'carton',
        width: '12%',
        align: 'right',
        sortable: true,
        fixed: false,
      },
      {
        text: '数量',
        value: 'number',
        width: '10%',
        align: 'right',
        sortable: true,
        fixed: false,
      },
      {
        text: '単価(円)',
        value: 'price',
        width: '12%',
        align: 'right',
        sortable: true,
        fixed: false,
      },
      {
        text: '総価(円)',
        value: 'total',
        width: '12%',
        align: 'right',
        sortable: true,
        fixed: false,
      },
    ],
    items: [
      {
        id: '1',
        date: '2022-09-01',
        product_name: '富良野【スープカレー】チキン',
        sku: 'ASB12234',
        jan: '4784789015172',
        carton: 1,
        number: 12,
        price: 1000,
        total: 1000,
      },
      {
        id: '2',
        date: '2022-09-01',
        product_name: '富良野【スープカレー】チキン',
        sku: 'ASB12234',
        jan: '4784789015172',
        carton: 2,
        number: 12,
        price: 1000,
        total: 2000,
      },
      {
        id: '3',
        date: '2022-09-02',
        product_name: '富良野【スープカレー】チキン',
        sku: 'ASB12234',
        jan: '4784789015172',
        carton: 3,
        number: 12,
        price: 1000,
        total: 3000,
      },
      {
        id: '4',
        date: '2022-09-02',
        product_name: '富良野【スープカレー】チキン',
        sku: 'ASB12234',
        jan: '4784789015172',
        carton: 4,
        number: 12,
        price: 1000,
        total: 4000,
      },
    ],
  }),
  computed: {
    TotalPrice() {
      let totals = 0
      for (let i = 0; i < this.items.length; i++) {
        const item = this.items[i]
        totals += item.total
      }

      return totals.toString().replace(/\B(?=(\d{3})+$)/g, ',')
    },
  },
  methods: {
    openForm() {
      this.dialog = true
    },
    goBack() {
      this.dialog = false
    },
  },
}
</script>
