var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"justify":"center"}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.openForm.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiEye)+" ")]),_c('v-dialog',{attrs:{"persistent":"","max-width":"900px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('div',[_c('v-card',{staticClass:"px-6 py-6"},[_c('div',{staticClass:"d-flex justify-center",staticStyle:{"font-size":"18px","letter-spacing":"1px"}},[_c('span',[_c('strong',[_vm._v("購買支払詳細")])])]),_c('v-row',{staticClass:"ma-4",attrs:{"no-gutters":""}},[_c('v-col',{staticStyle:{"font-size":"14px","line-height":"24px","font-weight":"bold"},attrs:{"cols":"5","sm":"2"}},[_c('div',[_c('span',[_vm._v("支払年月")])]),_c('div',[_c('span',[_vm._v("仕入先名")])]),_c('div',[_c('span',[_vm._v("仕入先コード")])])]),_c('v-col',{staticStyle:{"font-size":"14px","line-height":"24px"},attrs:{"cols":"7","sm":"4"}},[_c('div',[_c('span',[_vm._v("2022年9月31日")])]),_c('div',[_c('span',[_vm._v("江戸川商事")])]),_c('div',[_c('span',[_vm._v("JP1300")])])]),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-card-actions',{staticClass:"d-flex justify-end",staticStyle:{"height":"60px"}},[_c('v-btn',{staticClass:"me-3",attrs:{"color":"primary"},on:{"click":_vm.goBack}},[_c('span',[_vm._v("発送")])]),_c('v-btn',{staticClass:"me-3",attrs:{"color":"secondary","outlined":""},on:{"click":_vm.goBack}},[_c('span',[_vm._v("ダウンロード")])])],1)],1)],1),_c('v-data-table',{ref:"datatable",attrs:{"headers":_vm.headers,"items":_vm.items,"sort-by":""},scopedSlots:_vm._u([{key:"item.product_name",fn:function(ref){
var item = ref.item;
return [(item.product_name)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(item.product_name))])]}}],null,true)},[_c('span',[_vm._v("JANコード："+_vm._s(item.jan)),_c('br'),_vm._v("メーカー品番："+_vm._s(item.sku))])]):_vm._e()]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_c('label',[_vm._v(_vm._s(item.price.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')))])]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_c('label',[_vm._v(_vm._s(item.total.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')))])]}}],null,true)}),_c('v-row',{staticClass:"mt-3 mb-6"},[_c('v-spacer'),_c('v-col',{attrs:{"cols":"6","sm":"2"}},[_c('div',[_c('span',[_vm._v("合計："+_vm._s(_vm.TotalPrice))])])])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"me-3",attrs:{"color":"primary"},on:{"click":_vm.goBack}},[_c('span',{staticStyle:{"color":"#fff"}},[_vm._v("OK")])]),_c('v-btn',{staticClass:"me-3",attrs:{"color":"secondary","outlined":""},on:{"click":function($event){_vm.dialog = false}}},[_c('span',[_vm._v("キャンセル")])])],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }