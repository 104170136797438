<template>
  <div id="purchase-pay">
    <v-card>
      <v-card-title>支払一覧</v-card-title>
      <v-row class="px-2 ma-0">
        <v-col
          cols="12"
          md="2"
          sm="3"
        >
          <v-autocomplete
            v-model="supplierFilter"
            :items="suppliers"
            placeholder="仕入先名"
            outlined
            clearable
            dense
            hide-details
            class="me-3 mb-4"
          ></v-autocomplete>
        </v-col>
        <v-col
          cols="12"
          md="2"
          sm="3"
        >
          <v-text-field
            v-model="codeFilter"
            placeholder="仕入先コード"
            outlined
            hide-details
            dense
            class="me-3 mb-4"
          >
          </v-text-field>
        </v-col>
        <v-spacer></v-spacer>
        <v-col
          cols="12"
          md="2"
          sm="5"
        >
          <v-menu
            v-model="dateStartMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="startDate"
                placeholder="From"
                dense
                hide-details
                outlined
                readonly
                v-bind="attrs"
                :prepend-icon="icons.mdiCalendar"
                clearable
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="startDate"
              type="month"
              @input="dateStartMenu = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col
          cols="12"
          md="2"
          sm="5"
        >
          <v-menu
            v-model="dateEndMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="endDate"
                placeholder="To"
                dense
                outlined
                readonly
                hide-details
                v-bind="attrs"
                :prepend-icon="icons.mdiCalendar"
                clearable
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="endDate"
              type="month"
              @input="dateEndMenu = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-divider class="mt-4"></v-divider>
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <v-text-field
          v-model="search"
          placeholder="Search"
          outlined
          hide-details
          dense
          class="user-search me-3 mb-4"
        >
        </v-text-field>
        <v-spacer></v-spacer>
        <div class="d-flex align-center flex-wrap">
          <v-btn
            color="primary"
            class="mb-4 me-3"
            :to="{ name: 'purchase-pay-create', params: { query: this.$route.query } }"
          >
            <v-icon
              size="17"
              class="me-1"
            >
              {{ icons.mdiPlus }}
            </v-icon>
            <span>新規</span>
          </v-btn>
          <v-btn
            color="secondary"
            outlined
            class="mb-4 me-3"
            @click="downloadCSV"
          >
            <v-icon
              size="17"
              class="me-1"
            >
              {{ icons.mdiClipboardArrowDownOutline }}
            </v-icon>
            <span>エクスポート</span>
          </v-btn>
        </div>
      </v-card-text>
      <v-data-table
        ref="datatable"
        :headers="headers"
        :items="ProductList"
        sort-by
        class="elevation-1"
        :search="search"
      >
        <template v-slot:[`item.jan`]="{ item }">
          <v-tooltip bottom v-if="item.jan">
            <template v-slot:activator="{ on, attrs }">
              <span
                v-bind="attrs"
                v-on="on"
              > {{ item.jan }}</span>
            </template>
            <span>{{ item.product_name }}</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.price`]="{ item }">
          <label>{{ item.price.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') }}</label>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <div v-if="!item.processed">
            <Detail />
          </div>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
/* eslint-disable */
import {
  mdiCalendar, mdiPlus, mdiClipboardArrowDownOutline, mdiEye,
} from '@mdi/js'
import Detail from './PurchasePayDetail.vue'
/* eslint-disable */

export default {
  data: () => ({
    codeFilter: '',
    supplierFilter: '',
    quantity: '',
    dateStartMenu: '',
    startDate: '',
    dateEndMenu: '',
    endDate: '',
    search: '',
    arrBfo: [],
    icons: {
      mdiCalendar,
      mdiPlus,
      mdiClipboardArrowDownOutline,
      mdiEye,
    },
    suppliers: [
      '江戸川商事','中野商事','台東トレーディング','渋谷商事','目黒トレーディング','練馬商事','品川商店','足立商事','墨田トレーディング','世田谷商店','	杉並トレーディング'
    ],
    headers: [
      {
        text: 'ID',
        value: 'id',
        align: 'left',
        width: '5%',
        fixed: true,
      },
      {
        text: '支払年月',
        value: 'date',
        width: '10%',
        align: 'left',
        sortable: true,
        fixed: false,
      },
      {
        text: '仕入先コード',
        value: 'supplier_code',
        width: '10%',
        align: 'left',
        sortable: true,
        fixed: false,
      },
      {
        text: '仕入先名',
        value: 'supplier_name',
        width: '60%',
        align: 'left',
        sortable: true,
        fixed: false,
      },
      {
        text: '仕入価格(円)',
        value: 'price',
        width: '10%',
        align: 'right',
        sortable: true,
        fixed: false,
      },
      {
        text: '　',
        value: 'actions',
        sortable: false,
        width: '5%',
        align: 'right',
        fixed: true,
      },
    ],
    ProductList: [
      {
        id: '1',
        date: '2022-09',
        supplier_code: 'JP1300',
        supplier_name: '江戸川商事',
        price: 7980,
      },
      {
        id: '2',
        date: '2022-08',
        supplier_code: 'JP2145',
        supplier_name: '中野商事',
        price: 2980,
      },
      {
        id: '3',
        date: '2022-09',
        supplier_code: 'JP2315',
        supplier_name: '台東トレーディング',
        price: 13980,
      },
      {
        id: '4',
        date: '2022-10',
        supplier_code: 'JP5363',
        supplier_name: '渋谷商事',
        price: 22980,
      },
      {
        id: '5',
        date: '2022-11',
        supplier_code: 'JP7478',
        supplier_name: '目黒トレーディング',
        price: 8980,
      },
      {
        id: '6',
        date: '2022-12',
        supplier_code: 'JP8589',
        supplier_name: '練馬商事',
        price: 9980,
      },
      {
        id: '7',
        date: '2022-11',
        supplier_code: 'JP8542',
        supplier_name: '品川商店',
        price: 980,
      },
      {
        id: '8',
        date: '2022-10',
        supplier_code: 'JP9556',
        supplier_name: '足立商事',
        price: 55980,
      },
      {
        id: '9',
        date: '2022-09',
        supplier_code: 'JP5789',
        supplier_name: '墨田トレーディング',
        price: 6980,
      },
      {
        id: '10',
        date: '2022-08',
        supplier_code: 'JP5497',
        supplier_name: '世田谷商店',
        price: 89980,
      },
      {
        id: '11',
        date: '2022-09',
        supplier_code: 'JP3798',
        supplier_name: '杉並トレーディング',
        price: 9980,
      },
    ],
  }),
  components: {
    Detail,
  },
  watch: {
    codeFilter() {
      this.doFilter()
    },
    supplierFilter() {
      this.doFilter()
    },
    startDate() {
      this.doFilter()
    },
    endDate() {
      this.doFilter()
    },
  },
  created() {
    this.arrBfo = this.ProductList
  },
  methods: {
    doFilter() {
      let filterResult = this.arrBfo

      if (this.codeFilter !== null && this.codeFilter !== '') {
        filterResult = filterResult.filter(element => element.supplier_code === this.codeFilter)
      }

      if (this.supplierFilter !== null && this.supplierFilter !== '') {
        filterResult = filterResult.filter(element => element.supplier_name === this.supplierFilter)
      }

      if (this.startDate !== null && this.startDate !== '') {
        filterResult = filterResult.filter(element => element.date >= this.startDate)
      }

      if (this.endDate !== null && this.endDate !== '') {
        filterResult = filterResult.filter(element => element.date <= this.endDate)
      }
      this.ProductList = filterResult
    },
    downloadCSV() {
      let csv = '\ufeff + No.,ID,支払年月,仕入先コード,仕入先名,仕入価格(円),\n'
      let no = 0
      this.ProductList.forEach(el => {
        no += 1
        const line = `${no},"${el.id}","${el.date}","${el.supplier_code}","${el.supplier_name}","${el.price}",\n`
        csv += line
      })
      const blob = new Blob([csv], { type: 'text/csv' })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      const nowstr = new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substring(0, 19)
      const newstr = nowstr.replace(/(-|:|T)/g, '')
      link.download = `購買支払-${newstr}.csv`
      link.click()
    },
  },
}
</script>

<style>
.table-filed {
  white-space: nowrap;
}
.text-format {
  text-align: right !important;
}
.v-input__prepend-outer {
  margin: auto !important;
} 
.text-field-dense-append-prepend-margin {
  margin: auto;
}
.v-input__append-inner {
  margin: auto !important;
}
</style>
